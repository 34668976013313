
import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import CarouselSection from '@/components/shared/CarouselSection.vue';
import HeroSection from '@/components/AssignmentListByCourse/HeroSection.vue';
import Footer from '@/components/Footer/Footer.vue';
import { AssignmentListService } from '@/services';
import { EntregaPorDisciplinaLista } from '@/models/api';
import {
  CoverDataList,
  RouteName,
} from '@/models/';

const { ASSIGNMENT_DISCIPLINE_LIST } = RouteName;

@Component({
  name: 'AssignmentListByCourse',
  components: {
    Header,
    HeroSection,
    ButtonDefault,
    CarouselSection,
    Footer,
  },
})
export default class AssignmentListByCourse extends Vue {
  public carousels: CoverDataList[] = [];
  private cursoId: number;
  public route = ASSIGNMENT_DISCIPLINE_LIST;

  created() {
    this.cursoId = Number(this.$router.currentRoute.params.cursoId);
    this.getData();
  }

  get courseName() {
    return this.carousels[0]?.curso || '';
  }

  get courseId() {
    return this.cursoId;
  }

  private getData() {
    AssignmentListService.getCourseDisciplines(this.cursoId).then(
      (disciplinesResponse: AxiosResponse<EntregaPorDisciplinaLista[]>) => {
        this.carousels = disciplinesResponse.data
          .map((discipline: EntregaPorDisciplinaLista) => new CoverDataList(discipline));
      },
    );
  }
}
