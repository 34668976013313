
import { Component, Model, Vue } from 'vue-property-decorator';
import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import Footer from '@/components/Footer/Footer.vue';

@Component({
  name: 'HeroSection',
  components: {
    Header,
    ButtonDefault,
    Footer,
  },
})
export default class HeroSection extends Vue {
  @Model('change', { type: String }) value!: string;

  public output() {
    this.$emit('onChangeCourse');
  }
}
